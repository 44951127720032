import { Link } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Icon404 from "../images/icons/404"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window

  return (
    browser && (
      <Layout>
        <SEO title="404: Not found" />

        <div className="topics">
          <div className="main-content" style={{ marginTop: 117 }}>
            <div className="topics-header">
              <h1>Página Não Encontrada</h1>
              <p style={{ maxWidth: 600 }}>
                Não foi possível encontrar o que você estava procurando. O que
                acha de retornar ao{" "}
                <Link to="/pt" style={{ color: "#1E63AE" }}>
                  Início
                </Link>
                ?
              </p>
            </div>
            <div
              className="img-404"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <Icon404 />
            </div>
          </div>
        </div>
      </Layout>
    )
  )
}

export default NotFoundPage
